import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { userDetail } from "../../../Redux/userSlice";

const PlTechLauncher = () => {
    const {
        // p_code, p_type, g_code, operator, 
        p_username, p_password, p_lang, p_client, p_mode, p_game
    } = useParams();
    const navigate = useNavigate();
    const userInfo = useSelector(userDetail);

    // const [info, setInfo] = useState();

    const baseDomain = userInfo?.user?.user_base_origin;

    const [scriptLoaded, setScriptLoaded] = useState(false);

    // Log params for debugging
    // useEffect(() => {
    //     console.log(p_code, p_type, g_code, operator, p_username, p_password, p_lang, p_client, p_mode, p_game);
    // }, [p_code, p_type, g_code, operator, p_username, p_password, p_lang, p_client, p_mode, p_game]);

    useEffect(() => {

        // Load the external JS file dynamically
        let script = document.createElement('script');
        script.src = 'https://login.fdgon88.com/jswrapper/flyingdragon88/integration.js';
        document.head.appendChild(script);

        script.onload = () => {
            window.iapiSetCallout('Login', calloutLogin);
            //

            window.iapiConf.clientUrl_ngm_desktop = 'https://cachedownload.fdgon88.com/ngmdesktop/casinoclient.html'
            window.iapiConf.clientUrl_ngm_mobile = 'https://m.fdgon88.com/casinomobile/casinoclient.html';
            window.iapiConf.clientUrl_live_desk = 'https://cachedownload.fdgon88.com/live/'
            window.iapiConf.clientUrl_live_mob = 'https://cachedownload.fdgon88.com/live/';

            setScriptLoaded(true); // Script has been successfully loaded
        };
        script.onerror = () => {
            console.error("Failed to load external script.");
        };
        document.head.appendChild(script);

        return () => {
            // Cleanup: remove script if component unmounts
            document.head.removeChild(script);
        };
    }, []);

    const login = () => {
        if (!scriptLoaded) {
            console.error("Script not loaded yet.");
            return;
        }

        const username = p_username || document.getElementById("username").value;
        const password = p_password || document.getElementById("password").value;
        const lang = p_lang || document.getElementById("lang").value;
        const mode = p_mode || document.getElementById("mode").value;

        if (mode === 'real') {
            window.iapiSetClientType('casino');
            window.iapiSetClientPlatform('web');
            window.iapiLogin(username, password, 1, lang);
        } else {
            launchGameWithFunMode();
        }
    };

    const launchGame = () => {
        const client = p_client || document.getElementById("client").value;
        const mode = p_mode || document.getElementById("mode").value;
        const game = p_game || document.getElementById("game").value;
        const lang = p_lang || document.getElementById("lang").value;
        const real = (mode === 'real') ? 1 : 0;

        let lobbyUrl = '', logoutUrl = '', supportUrl = '', depositUrl = '', backUrl = `${baseDomain}/casino`;

        if (client === 'ngm_desktop' || client === 'ngm_mobile') {
            window.iapiSetClientParams(client, `language=${lang}&real=${real}&lobby=${lobbyUrl}&logout=${logoutUrl}&deposit=${depositUrl}&support=${supportUrl}&backurl=${backUrl}`);
            window.iapiLaunchClient(client, game, mode, '_self');
        }

        if (client === 'live_desk' || client === 'live_mob') {
            window.iapiSetClientParams(client, `&launch_alias=${game}&language=${lang}&real=${real}&lobby=${lobbyUrl}&logout=${logoutUrl}&deposit=${depositUrl}&support=${supportUrl}`);
            window.iapiLaunchClient(client, null, mode, '_self');
        }
    };

    const launchGameWithFunMode = () => {
        const client = p_client || document.getElementById("client").value;
        const game = p_game || document.getElementById("game").value;
        const lang = p_lang || document.getElementById("lang").value;
        const mode = p_mode || document.getElementById("mode").value;

        if (client === 'ngm_desktop' || client === 'ngm_mobile') {
            window.iapiSetClientParams(client, `language=${lang}&real=0`);
            window.iapiLaunchClient(client, game, mode, '_self');
        }
    };

    const calloutLogin = (response) => {
        // console.log(response);
        if (response.errorCode) {
            if (response.errorCode === 48) {
                alert(`Login failed, error: ${response.errorCode} playerMessage: ${response.actions.PlayerActionShowMessage[0].message}`);
            } else {
                alert(`Login failed, error: ${response.errorCode} playerMessage: ${response.playerMessage}`);
            }
        } else {
            launchGame();
        }
    };

    useEffect(() => {
        if (scriptLoaded && p_username && p_password && p_lang && p_client && p_mode && p_game) {
            login();
            // navigate("/");
        }
    }, [scriptLoaded, p_username, p_password, p_lang, p_client, p_mode, p_game]);

    return (
        <div className="d-none">
            <label htmlFor="username">Username:</label>
            <input type="text" id="username" name="username" placeholder="Must be capital letters" /><br /><br />
            <label htmlFor="password">Password:</label>
            <input type="text" id="password" name="password" /><br /><br />
            <label htmlFor="lang">Language:</label>
            <input type="text" id="lang" name="lang" placeholder="en,zh-CN,...." /><br /><br />
            <label htmlFor="client">Client:</label>
            <input type="text" id="client" name="client" placeholder="ngm_desktop,ngm_mobile,live_desk,live_mob" size="50" /><br /><br />
            <label htmlFor="mode">Mode:</label>
            <input type="text" id="mode" name="mode" placeholder="real,offline" /><br /><br />
            <label htmlFor="game">Game Code:</label>
            <input type="text" id="game" name="game" /><br /><br />
            <button id="PlTech" onClick={login}>Login and Launch</button>
        </div>
    );
};

export default PlTechLauncher;